.card {
    width: 300px;
    border: 1px solid #ddd;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    margin: 10px;
    transition: box-shadow 0.3s ease;
  }
  
  .card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .cardImage {
    width: 100%;
    height: 180px;
    object-fit: cover;
  }
  
  .cardContent {
    padding: 15px;
  }
  
  .cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .centerName {
    font-size: 16px;
    color: #006F45;
    line-height: 1.5rem;
    margin: 0;
    padding-bottom: 0px;
  }
  
  .centerDistance {
    font-size: 14px;
    color: #666;
  }
  
  .centerAddress {
    font-size: 13px;
    color: #7A7979;
    margin: 2px 0 20px;
    text-wrap: wrap;
    width: 65%;
    font-family: 'Sailec', sans-serif;

  }
  
  .timeSlots {
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
  
  .timeSlot {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    background-color: #f8f9fa;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .timeSlot.selected {
    background-color: #28a745;
    color: #fff;
    border-color: #28a745;
  }
  
  .timeSlot:hover {
    background-color: #e9ecef;
  }
  
  .headingParent{
    display: flex;
    justify-content: space-between;
  }

  .timeCount{
    font-family: 'Sailec', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 15px;
    text-align: center;
    height: 40px;
    width: 100%;

  }

  @media (min-width: 0px) and (max-width: 768px){
  .timeCount{
    font-family: 'Sailec', sans-serif;
        font-size: 11px;
        font-weight: 500;
        height: 40px;
        line-height: 15px;
        text-align: center;
        width: 100%;
        padding: 0px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px){
    .timeCount{
      font-family: 'Sailec', sans-serif;
          font-size: 12px;
          font-weight: 500;
          height: 40px;
          line-height: 15px;
          text-align: center;
          width: 100%;
          padding: 0px;
      }
    }


  .cardParent{
    width: 30%;
    height: 30rem;
    overflow: hidden;
    min-height: 14rem;
  }


  @media (min-width: 0px) and (max-width: 768px){
    .cardParent{
      width: 100%;
      display: flex;
      flex-direction: column;
      height: 30rem;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px){
    .cardParent{
        width: 45.333%;
        height: 30rem;
    }
  }

  @media (min-width: 1025px) and (max-width: 1300px){
    .cardParent{
        height: 31rem;
    }
  }

  .imgFix{
    width: 100%;
    height: auto;
    object-fit: cover; /* or contain, fill, none, scale-down depending on your needs */
    max-width: 100%;
    display: block;
    height: 35%;
  }

  .slotsFix{
    height: 150px;
    overflow-y: auto;
    gap: 10px;
    margin-bottom: 10px;
    margin-top: 10px;
    
  }

  .noFix{
    height: 150px;
    overflow-y: auto;
    gap: 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .customSelect{
      background-color: #017e5f;
      border-color: #017e5f;
      color: white;
      outline: none;
  }

  .urgentCare{
    font-size: 13px;
    margin-top: -12px;
  }

  .urgentCareHidden{
    font-size: 13px;
    margin-top: -12px;
    visibility: hidden;
  }

  .urgentCareParent{
    display: flex;
    justify-content:space-between;
    margin-bottom: 2px;
  }

  .urgentCareacceptedImage{
    max-height: 30px;
  }

  .phoneNumber{
    line-height: 30px;
    color: #017e5f;
    font-size: 15px;
    font-weight: 600;
    display: flex;

  }

  @media (min-width: 0px) and (max-width: 768px){
    .phoneNumber{
      font-size: 13px;
      
  
    }
  }