.cardHeight{
    height: 229px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: thin;
    scrollbar-color: #017e5f #e0e0e0;
  }
  
.buttonHeight{
  height: 40px;
  width: 106px;
}

.boxWidth{
  max-width: 25rem !important;
}

.boxFix{
  display: flex;
  justify-content: space-around;
}


@media (min-width: 0px) and (max-width: 767px){
    .boxFix{
        display: block;
      }
}


.App {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 50px;
  grid-template-columns: repeat(3, 1fr);
  margin-left: 2rem;
  margin-right: 2rem;
}

@media (min-width: 0px) and (max-width: 768px){
  .cardsContainer {
    margin-left: 0rem;
    margin-right: 0rem;
    
  }
  
  }

/* @media (min-width: 1024px){
  .cardsContainer {
    margin-left: 5rem;
    margin-right: 5rem;
  }
  } */


.searchBar{
  padding: 10px;
  font-size: 16px;
  width: 200px;
  margin-right: 10px;
}

.locationButton {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.headingStyle{
  font-family: 'Sailec';
  font-size: 16px;
  font-weight: 500;
  line-height: 17.5px;
  ;
}

.parent{
  margin-left: 2rem;
  margin-right: 2rem;
  width:94%;
}

@media (min-width: 0px) and (max-width: 768px){
.parent{
  display: block;
  margin-right: 0rem;
  margin-left: 0rem;
}

}

@media (min-width: 0px) and (max-width: 768px){
.currLocParent{
  margin-left: 0px !important;
  margin-top: 10px !important;
}
}

@media (min-width: 768px) and (max-width: 1024px){
  .currLocParent{
    margin-left: 0px !important;
    margin-top: 5px !important;
  }
  }

.reasonVisit{
  width: 50%;
}

@media (min-width: 0px) and (max-width: 768px){
  .reasonVisit{
    width: 100% !important;
  }
  
  }

  @media (min-width: 768px) and (max-width: 1024px){
    .reasonVisit{
      width: 65% !important;
    }
    
    }

.users_btn {
  border: 1px solid #F3F3F3;
  border-radius: 9px;
  background-color: #FFFFFF;
  padding: 7px 10px 7px 10px;
  margin-bottom: 17px;
  width: 50%;
  display: flex;
  text-align: center;
}

@media (min-width: 0px) and (max-width: 768px){
  .users_btn{
    width: 100% !important;
  }
  }

  @media (min-width: 768px) and (max-width: 1024px){
    .users_btn{
      width: 80% !important;
    }
  }

.users_btn .u_btn {
  flex: 100% 1;
  align-self: center;
  padding: 15px 10px;
  margin-right: 3px;
  background: white;
  font-size: 15px;
  outline: none;
  border: 1px solid transparent; 
}

@media (min-width: 0px) and (max-width: 768px){
  .users_btn .u_btn{
    font-size: 14px;
  }
  
  }

.users_btn .u_btn.returning_active {
  border: 1px solid #F3F3F3;
  border-radius: 9px;
  background-color: #017E5F;
  color: #fff;
  font-size: 15px;
}

@media (min-width: 0px) and (max-width: 768px){
  .users_btn .u_btn.returning_active{
    font-size: 14px;
  }
  
  }

@media (min-width: 0px) and (max-width: 768px){
  .location_center_parent{
    display: block;
  }
  
  }
  @media (min-width: 768px) and (max-width: 1024px){
    .location_center_parent{
      flex-wrap: wrap;
    }
    
    }

@media (min-width: 0px) and (max-width: 768px){
  .use_my_location{
   font-size: 14px;
  }
  
  }

  @media (min-width: 0px) and (max-width: 768px){
    .inputFix{
     width: 100%;
    }
    
    }

    .inputFix {
      height: 55px;
      width: 100%;
      border: 2px solid #EBEBEB;
      border-radius: 9px;
      background-color: #FFFFFF;
      max-width: unset;
    }

    .dropdownParent{
      position: relative;
      width: 100%;
    }


     .locationDropdown {
      position: absolute;
      z-index: 999;
    }
    
    .locationDropdown .cusDropdown {
      height: 100%;
      height: 200px;
      overflow-y: scroll;
      margin-bottom: 200px;
      width: 100%;
      background-color: #fff;
      border: 2px solid #ebebeb;
      border-radius: 9px;
    }
    
    
    .locationDropdown .customeMedia {
    
      padding: 10px 10px;
    
    }
    
    .locationDropdown .customeMedia .customeMediaImg {
      margin-right: 10px;
    }


    .customeMedia {
      display: flex;
    }
    
    .customeMediaBody {
      flex: 1;
    }
    
    .customeMediaImage {
      height: auto;
      width: 50px;
      align-self: center;
      margin-right: 10px;
    }

    .block-style:hover svg {
      --transform-translate-x: 0;
      --transform-translate-y: 0;
      --transform-rotate: 0;
      --transform-skew-x: 0;
      --transform-skew-y: 0;
      --transform-scale-x: 1;
      --transform-scale-y: 1;
      transform: translateX(var(--transform-translate-x)) translateY(var(--transform-translate-y)) rotate(var(--transform-rotate)) skewX(var(--transform-skew-x)) skewY(var(--transform-skew-y)) scaleX(var(--transform-scale-x)) scaleY(var(--transform-scale-y));
      --transform-translate-x: 1rem;
    }

    .greenButton .blockStyle {
      --bg-opacity: 1;
      background-color: #017E5F;
      background-color: rgba(1, 126, 95, var(--bg-opacity));
    }
    
    .greenButton .blockStyle:hover {
      --bg-opacity: 1;
      background-color: #155750;
      background-color: rgba(21, 87, 80, var(--bg-opacity));
    }
    
    .greenButton .blockStyle {
      border-radius: 0.25rem;
      --text-opacity: 1;
      color: #FFFFFF;
      color: rgba(255, 255, 255, var(--text-opacity));
      transition-property: all;
      transition-duration: 150ms;
    }
    
    .greenButton-text .blockStyle {
      background-color: transparent;
      border-radius: 0.25rem;
      cursor: pointer;
      display: inline-block;
      font-weight: 700;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      padding-left: 1rem;
      padding-right: 1rem;
      --text-opacity: 1;
      color: #017E5F;
      color: rgba(1, 126, 95, var(--text-opacity));
    }
    
    .greenButton-text .blockStyle svg {
      display: inline-block;
    }

    .book-appoiment-loader .snippet {
      width: 100%;
      height: 180px;
      border: 1px solid #F3F3F3;
      border-radius: 14px;
      background-color: #FFFFFF;
      position: relative;
    
    }
    
    
    .bookAappoimentLoader .stage {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 2rem 0;
      overflow: hidden;
    }
  
    .loaderContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
      width: 100%;
      background-color: #F4F3F0;
    }